<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("customer_form") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <Language />
      <v-btn @click="formClose" dark icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-items>
        <v-btn @click="reset" dark text v-if="snackbar.save_button">Save</v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-stepper v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        {{ $t("customer_form") }}
        <small>{{ $t("fill_out_the_customer_record_form") }}</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <form class="px-2 fix-for-nepali-label">
          <v-row>
            <v-col md="8" cols="12">
              <v-row>
                <v-col md="4" sm="6" cols="12" class="pt-6">
                  <v-text-field
                    :error-messages="NumberOfPersonErrors"
                    @blur="$v.formData_1.NumberOfPerson.$touch()"
                    @input="$v.formData_1.NumberOfPerson.$touch()"
                    clearable
                    dense
                    outlined
                    required
                    v-model="formData_1.NumberOfPerson"
                  >
                    <template v-slot:label>
                      {{ $t("no_of_person") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12" class="pt-6">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                    v-model="snackbar.menu2"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        clearable
                        prepend-inner-icon="event"
                        readonly
                        dense
                        outlined
                        v-model="formData_1.CheckInDate"
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t("check_in_date") }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :error-messages="CheckInDateErrors"
                      @blur="$v.formData_1.CheckInDate.$touch()"
                      @input="$v.formData_1.CheckInDate.$touch()"
                      v-model="formData_1.CheckInDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="4" sm="6" cols="12" class="pt-6">
                  <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="290px"
                    offset-y
                    transition="scale-transition"
                    v-model="snackbar.menu3"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        clearable
                        prepend-inner-icon="event"
                        readonly
                        dense
                        outlined
                        v-model="formData_1.CheckOutDate"
                        
                        v-on="on"
                      >
                        <template v-slot:label>
                          {{ $t("check_out_date") }}
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      :error-messages="CheckOutDateErrors"
                      @blur="$v.formData_1.CheckOutDate.$touch()"
                      @change="getPAX"
                      @input="$v.formData_1.CheckOutDate.$touch()"
                      v-model="formData_1.CheckOutDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                  <v-text-field :items="males" v-model.number="formData_1.totalMale"
                    :error-messages="NumberOfMaleErrors"
                    dense
                    outlined
                    @blur="$v.formData_1.totalMale.$touch()"
                    @input="$v.formData_1.totalMale.$touch()"
                    >
                    <template v-slot:label>
                      {{ $t("no_of_male") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                  <v-text-field
                    :items="females"
                    v-model.number="formData_1.totalFemale"
                    label="No. of Female"
                    dense
                    outlined
                    :error-messages="NumberOfFemaleErrors"
                    @blur="$v.formData_1.totalFemale.$touch()"
                    @input="$v.formData_1.totalFemale.$touch()"
                  >
                    <template v-slot:label>
                      {{ $t("no_of_female") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                  <v-text-field
                    :items="children"
                    v-model.number="formData_1.totalChildren"
                    label="No. of Children"
                    dense
                    outlined
                    :error-messages="NumberOfChildrenErrors"
                    @blur="$v.formData_1.totalChildren.$touch()"
                    @input="$v.formData_1.totalChildren.$touch()"
                  >
                    <template v-slot:label>
                      {{ $t("no_of_children") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                  <v-select
                    v-model="formData_1.customerType"
                    :items="customerTypes"
                            dense
                            outlined
                    item-value="id"
                    item-text="value"
                    multiple
                  >
                    <template v-slot:label>
                      {{ $t("customer_type") }}
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  v-if="formData_1.customerType.includes(1)"
                >
                  <v-select
                    v-model="formData_1.countries"
                    :items="listCountries"
                    item-text="value"
                            dense
                            outlined
                    item-value="id"
                    label="Country"
                    clearable
                    multiple
                  >
                    <template v-slot:label>
                      {{ $t("country") }}
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  v-if="formData_1.customerType.includes(1)"
                >
                  <v-text-field
                    clearable
                    required
                                dense
                            outlined
                    v-model="formData_1.internationalCustomer"
                  >
                    <template v-slot:label>
                      {{ $t("international") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  v-if="formData_1.customerType.includes(2)"
                >
                  <v-text-field
                    clearable
                    required
                                dense
                            outlined
                    v-model="formData_1.domesticCustomer"
                  >
                    <template v-slot:label>
                      {{ $t("domestic") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                  <v-text-field
                    :items="visit"
                                dense
                            outlined
                    v-model="formData_1.purposeOfVisit"
                  >
                    <template v-slot:label>
                      {{ $t("purpose_of_visit") }}
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="4" sm="6" cols="12">
                    <v-autocomplete :error-messages="selectHouseErrors"
                                    :items="paxDropDownList"
                                    item-value="id"
                                    item-text="value"
                                    clearable
                                    multiple
                                    dense
                            outlined
                                    v-model="formData_1.roomCategoryID"
                                    @change="roomCategoryChanged()">
                        <template v-slot:label>
                            {{ $t("select_house") }}
                        </template>
                    </v-autocomplete>
                </v-col>

                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  v-for="(house, index) in formData_1.roomCategoryID"
                  :key="index"
                >
                  <v-text-field
                    :key="index"
                                type="number"
                                dense
                            outlined
                    v-model="formData_1.houseTypeCount[index]"
                                :max="selectedHouse[index].availableCount||1"
                                :error-messages="selectedHouseErrors[index]"
                  >
                    <template v-if="!isNepaliLanguage" v-slot:label>
                      No. of People in
                      {{ houseType.find((res) => res.id === house).value }}
                    </template>
                    <template v-else v-slot:label>
                      {{ houseType.find((res) => res.id === house).value }} मा
                      व्यक्ति संख्या
                    </template>
                  </v-text-field>
                </v-col>

                <v-col md="4" sm="6" cols="12">
                  <v-autocomplete
                    :items="ageGroup"
                    clearable
                                  dense
                            outlined
                    item-text="value"
                    item-value="id"
                    required
                    multiple
                    v-model="formData_1.ageGroup"
                  >
                    <template v-slot:label>
                      {{ $t("age_group") }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="4" sm="6" cols="12">
              <v-date-picker
                no-title
                class="col-md-3 col-sm-6"
                v-model="picker"
              ></v-date-picker>
            </v-col>
            <v-col md="12">
              <v-card elevation="4">
                <v-card-title>
                  <template v-slot:label>
                    {{ $t("contact_information") }}
                  </template>
                </v-card-title>
                <v-row
                  class="pl-4"
                  v-for="(contact, index) in formData_1.contactPerson"
                  :key="index"
                >
                  <v-col md="3" sm="6" cols="12">
                    <v-text-field clearable dense outlined required v-model="contact.name">
                      <template v-slot:label>
                        {{ $t("name") }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="2" sm="6" cols="12">
                    <v-text-field
                      clearable
                      required
                                  dense
                                  outlined
                      v-model="contact.phoneNumber"
                    >
                      <template v-slot:label>
                        {{ $t("contact_no") }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="3" sm="6" cols="12">
                    <v-text-field clearable dense outlined required v-model="contact.email">
                      <template v-slot:label>
                        {{ $t("email") }}
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col md="3" sm="6" cols="12">
                    <v-text-field clearable dense outlined required v-model="contact.address">
                      <template v-slot:label>
                        {{ $t("address") }}
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <v-icon @click="addContacts" color="green" class="mr-2">
                      mdi-plus</v-icon
                    >
                    <v-icon
                      v-if="formData_1.contactPerson.length != 1"
                      @click="formData_1.contactPerson.splice(index, 1)"
                      color="red"
                      >mdi-delete</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <!--                        <v-autocomplete v-model="formData_1.Package"-->
            <!--                                        :items="packages"-->
            <!--                                        label="Package"-->
            <!--                                        clearable-->
            <!--                                        single-line-->
            <!--                                        item-text="value"-->
            <!--                                        item-value="id"-->
            <!--                                        class="col-sm-6 col-md-4"-->
            <!--                                        @change="getPrice"></v-autocomplete>-->
            <!--                        <v-autocomplete v-model="formData_1.Service"-->
            <!--                                        :items="services"-->
            <!--                                        chips-->
            <!--                                        deletable-chips-->
            <!--                                        label="Services"-->
            <!--                                        clearable-->
            <!--                                        multiple-->
            <!--                                        item-text="value"-->
            <!--                                        item-value="id"-->
            <!--                                        class="col-sm-6 col-md-4"-->
            <!--                                        @change="getServicePrice"></v-autocomplete>-->
            <!--                        <v-switch class="col-sm-6 col-md-4"-->
            <!--                                  v-model="formData_1.isCustomerOnPackage"-->
            <!--                                  label="Is Customer On Package?"-->
            <!--                                  clearable-->
            <!--                                  @change="getPrice"></v-switch>-->

            <div class="col-12">
                <!--                            <v-col md="4">-->
                <!--                                Total Fare : RS {{snackbar.price}}-->
                <!--                            </v-col>-->
                <!--                            <v-col md="4">-->
                <!--                                Service Charge: RS {{snackbar.ServicePrice}}-->
                <!--                            </v-col>-->
                <!--                            <br/>-->
                <v-btn @click="formClose"
                       class="red darken-1 mr-2"
                       dark
                       depressed>{{ $t("cancel") }}</v-btn> 
                <v-btn @click="form_1Post"
                       class="mr-4 green darken-1"
                       dark
                       depressed>{{ $t("save") }}</v-btn>

                <!-- <v-btn dark depressed class="mr-4 blue darken-1" @click="form_next" :disabled="!snackbar.next_button">Next</v-btn>-->
            </div>
          </v-row>
        </form>
      </v-stepper-content>

      <!--<v-stepper-step :complete="e6 > 2" step="2">Customer Information</v-stepper-step>

    <v-stepper-content step="2">
        <v-expansion-panels>
            <v-expansion-panel :key="i" v-for="(item,i) in snackbar.number_of_second_form">
                <v-expansion-panel-header>Person {{item}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <form class="px-2">
                        <v-card class="my-12">
                            <v-toolbar class="darken-1" color="blue" dark fixed>
                                <v-toolbar-title>
                                    Find Previous ID
                                </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>-->
      <!--                                        <v-row> -->
      <!--                                            <v-col-->
      <!--                                                    cols="12"-->
      <!--                                                    sm="6"-->
      <!--                                            >-->
      <!--                                                <v-autocomplete-->
      <!--                                                        :items="citizenshipData"-->
      <!--                                                        :loading="isLoading"-->
      <!--                                                        :search-input.sync="searchCitizen"-->
      <!--                                                        @change="getCitizenData(i,dropdownData[i].citizenShipNumber)"-->
      <!--                                                        color="white"-->
      <!--                                                        hide-selected-->
      <!--                                                        clearable-->
      <!--                                                        item-text="id"-->
      <!--                                                        item-value="value"-->
      <!--                                                        label="Enter your ID"-->
      <!--                                                        placeholder="Citizenship Number"-->
      <!--                                                        prepend-icon="mdi-database-search"-->
      <!--                                                        return-object-->
      <!--                                                        v-model="dropdownData[i].citizenShipNumber"-->
      <!--                                                ></v-autocomplete>-->
      <!--                                            </v-col>-->
      <!--                                            <v-col-->
      <!--                                                    cols="12"-->
      <!--                                                    sm="6"-->
      <!--                                            >-->
      <!--                                                <v-autocomplete-->
      <!--                                                        :items="passportData"-->
      <!--                                                        :loading="isLoading"-->
      <!--                                                        :search-input.sync="searchPassport"-->
      <!--                                                        @change="getPassportData(i,dropdownData[i].passportNumber)"-->
      <!--                                                        color="white"-->
      <!--                                                        hide-selected-->
      <!--                                                        clearable-->
      <!--                                                        item-text="id"-->
      <!--                                                        item-value="value"-->
      <!--                                                        label="Enter your ID"-->
      <!--                                                        placeholder="Passport Number"-->
      <!--                                                        prepend-icon="mdi-database-search"-->
      <!--                                                        return-object-->
      <!--                                                        v-model="dropdownData[i].passportNumber"-->
      <!--                                                ></v-autocomplete>-->
      <!--                                            </v-col>-->
      <!--                                        </v-row>-->
      <!--<v-row>
                <v-col cols="12" sm="6">
                    <v-select :items="['Passport','Citizen']"
                              label="Type"
                              v-model="trackType">
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-text-field label="Enter your ID"
                                  v-model="trackID"></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                    <v-spacer></v-spacer>
                    <v-btn @click="getUserData(i)" class="mx-2" color="primary" dark small>
                        <v-icon dark> search</v-icon>
                        search
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>-->
      <!--<v-row>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="First Name"
                      v-model="formData_2[i].fullName"></v-text-field>
        <v-autocomplete :items="countries"
                        @change="getState"
                        class="col-sm-6 col-md-4"
                        clearable
                        item-text="value"
                        item-value="id"
                        label="Country"
                        single-line
                        v-model="formData_2[i].country"></v-autocomplete>
        <v-autocomplete :items="state"
                        class="col-sm-6 col-md-4"
                        clearable
                        item-text="value"
                        item-value="id"
                        label="State"
                        single-line
                        v-model="formData_2[i].state"></v-autocomplete>
        <v-autocomplete :items="genderList"
                        class="col-sm-6 col-md-4"
                        clearable
                        item-text="value"
                        item-value="id"
                        label="Gender"
                        single-line
                        v-model="formData_2[i].gender"></v-autocomplete>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="Age"
                      v-model="formData_2[i].age"></v-text-field>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="CitizenShipNumber"
                      v-model="formData_2[i].citizenShipNumber"></v-text-field>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="PassportNumber"
                      v-model="formData_2[i].passportNumber"></v-text-field>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="LicenseNumber"
                      v-model="formData_2[i].licenseNumber"></v-text-field>
        <v-text-field class="col-sm-6 col-md-4"
                      clearable
                      label="OtherID"
                      v-model="formData_2[i].otherID"></v-text-field>-->
      <!--<v-menu :close-on-content-click="false"
            :nudge-right="40"
            class="col-sm-6 col-md-4"
            min-width="290px"
            offset-y
            transition="scale-transition"
            v-model="snackbar.dateMenu1[i]">
        <template v-slot:activator="{ on }">
            <v-text-field clearable
                          label="Check In Date"
                          prepend-icon="event"
                          readonly
                          v-model="formData_2[i].HotelInformation.CheckInDate"
                          v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="formData_2[i].HotelInformation.CheckInDate"></v-date-picker>

    </v-menu>-->
      <!--<v-menu :close-on-content-click="false"
            :nudge-right="40"
            class="col-sm-6 col-md-4"
            min-width="290px"
            offset-y
            transition="scale-transition"
            v-model="snackbar.dateMenu2[i]">
        <template v-slot:activator="{ on }">
            <v-text-field clearable
                          label="Check Out Date"
                          prepend-icon="event"
                          readonly
                          v-model="formData_2[i].HotelInformation.CheckOutDate"
                          v-on="on"></v-text-field>
        </template>
        <v-date-picker changeCustomerFormCheckoutDate
                       v-model="formData_2[i].HotelInformation.CheckOutDate"></v-date-picker>
    </v-menu>-->
      <!--                                    <v-menu v-model="snackbar.dateMenu3[i]"-->
      <!--                                            :close-on-content-click="false"-->
      <!--                                            :nudge-right="40"-->
      <!--                                            transition="scale-transition"-->
      <!--                                            offset-y-->
      <!--                                            min-width="290px"-->
      <!--                                            class="col-sm-6 col-md-4"-->
      <!--                                    >-->
      <!--                                        <template v-slot:activator="{ on }">-->
      <!--                                            <v-text-field-->
      <!--                                                    v-model="formData_2[i].IDValidity "-->
      <!--                                                    label="ID Validity"-->
      <!--                                                    clearable-->
      <!--                                                    prepend-icon="event"-->
      <!--                                                    readonly-->
      <!--                                                    v-on="on"-->
      <!--                                            ></v-text-field>-->
      <!--                                        </template>-->
      <!--                                        <v-date-picker v-model="formData_2[i].IDValidity ">-->
      <!--                                        </v-date-picker>-->
      <!--                                    </v-menu>-->
      <!--                                    <v-menu v-model="snackbar.dateMenu4[i]"-->
      <!--                                            :close-on-content-click="false"-->
      <!--                                            :nudge-right="40"-->
      <!--                                            transition="scale-transition"-->
      <!--                                            offset-y-->
      <!--                                            min-width="290px"-->
      <!--                                            class="col-sm-6 col-md-4"-->
      <!--                                    >-->
      <!--                                        <template v-slot:activator="{ on }">-->
      <!--                                            <v-text-field-->
      <!--                                                    v-model="formData_2[i].visaValidity "-->
      <!--                                                    label="Visa Validity"-->
      <!--                                                    clearable-->
      <!--                                                    prepend-icon="event"-->
      <!--                                                    readonly-->
      <!--                                                    v-on="on"-->
      <!--                                            ></v-text-field>-->
      <!--                                        </template>-->
      <!--                                        <v-date-picker v-model="formData_2[i].visaValidity  ">-->
      <!--                                        </v-date-picker>-->
      <!--                                    </v-menu>-->
      <!--<v-text-field class="col-sm-6 col-md-4"
                                          clearable
                                          label="Visa Validity"
                                          readonly
                                          v-if="formData_2[i].passportNumber"
                                          v-model="validaty[i].visaValidity"></v-text-field>

                            <v-text-field class="col-sm-6 col-md-4"
                                          clearable
                                          label="Id Validity"
                                          readonly
                                          v-if="formData_2[i].passportNumber"
                                          v-model="validaty[i].visaValidity"></v-text-field>

                            <v-autocomplete :items="selectedRoomData[i].selectedData"
                                            chips
                                            class="col-sm-6 col-md-4"
                                            clearable
                                            deletable-chips
                                            item-text="roomDisplay"
                                            item-value="roomID"
                                            label="Rooms"
                                            v-model="formData_2[i].HotelInformation.RoomID"></v-autocomplete>
                        </v-row>
                    </form>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <div class="col-4">
            <v-btn @click="form_2Post" class="mr-4 green darken-1" dark depressed>Save</v-btn>
        </div>
    </v-stepper-content>-->
      <!--            <v-stepper-step :complete="e6 > 3" step="3">Bill</v-stepper-step>-->
      <!--            <v-stepper-content step="3">-->
      <!--                <v-form>-->
      <!--                    <v-container>-->
      <!--                        <v-row>-->
      <!--                            <v-col cols="12" sm="6" md="4">-->
      <!--                                <v-text-field :value="snackbar.price+snackbar.ServicePrice " label="Price To Be Paid"-->
      <!--                                              disabled></v-text-field>-->
      <!--                            </v-col>-->
      <!--                            <v-col cols="12" sm="6" md="4">-->
      <!--                                <v-text-field v-model="BillForm.PaidAmount" label="Paid Amount"></v-text-field>-->
      <!--                            </v-col>-->
      <!--                            <v-col cols="12" sm="6" md="4">-->
      <!--                                <v-autocomplete v-model="BillForm.PaymentType" :items="paymentType"-->
      <!--                                                label="Payment Type"></v-autocomplete>-->
      <!--                            </v-col>-->
      <!--                            <v-col cols="12" sm="6" md="4">-->
      <!--                                <v-text-field v-model="BillForm.DueAmount" label="Due Amount"></v-text-field>-->
      <!--                            </v-col>-->
      <!--                            <v-col cols="12"></v-col>-->
      <!--                        </v-row>-->
      <!--                    </v-container>-->
      <!--                </v-form>-->
      <!--                <div class="col-4">-->
      <!--                    <v-btn-->
      <!--                            dark-->
      <!--                            depressed-->
      <!--                            class="mr-4 green darken-1"-->
      <!--                            @click="form_3Post"-->
      <!--                            :disabled="!snackbar.next_button"-->
      <!--                    >Save-->
      <!--                    </v-btn>-->
      <!--                </div>-->
      <!--            </v-stepper-content>-->
    </v-stepper>
    <v-snackbar
      :timeout="snackbar.timeout"
      color="error"
      top
      v-model="snackbar.snackbar"
    >
      {{ snackbar.text }}
      <v-btn @click="snackbar.snackbar = false" text>Close</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
/*import { email, maxLength,, sameAs } from "vuelidate/lib/validators";*/
import axios from "axios";
    import { required } from "vuelidate/lib/validators";
    import Language from "@/components/Language";
    const maxValueValids = (vm) => vm.sumOfMaleFemaleChildren <= vm.formData_1.NumberOfPerson
    export default {
        name: "CreateHomeStayCustomerForm",
        props: ["pendingData"],
        components: { Language },
        watch: {
            searchCitizen(val) {
                console.log(val);
                if (val[0]) {
                    this.items = [];
                    // Items have already been loaded
                    if (this.items.length > 0) return;

                    // Items have already been requested
                    if (this.isLoading) return;

                    this.isLoading = true;

                    // Lazily load input items

                    axios
                        .get("Track/GetCitizenIDListAsync", {
                            params: {
                                CitizenID: val[0],
                            },
                        })
                        .then((res) => {
                            this.citizenshipData = res.data;
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.isLoading = false));
                }
            },
            searchPassport(val) {
                console.log(val);
                if (val[0]) {
                    this.items = [];
                    // Items have already been loaded
                    if (this.items.length > 0) return;

                    // Items have already been requested
                    if (this.isLoading) return;

                    this.isLoading = true;

                    // Lazily load input items
                    axios
                        .get("Track/GetPassportIDListAsync", {
                            params: {
                                PassportNumber: val[0],
                            },
                        })
                        .then((res) => {
                            this.passportData = res.data;
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => (this.isLoading = false));
                }
            },
        },
        data() {
            return {
                TotalCurrentPax: 0,
                TotalAvailablePax: 0,
                listCountries: [],
                storeRoom: [],
                trackType: "Passport",
                trackID: "",
                citizenshipData: [],
                passportData: [],
                dropdownData: [],
                isLoading: false,
                searchCitizen: "",
                searchPassport: "",
                customerTypes: [],
                e6: 1,
                step: 1,
                snackbar: {
                    available: null,
                    snackbar: false,
                    text: "Internal Server Error,Please Contact Admin",
                    timeout: 200000,
                    menu2: false,
                    menu3: false,
                    dateMenu4: [],
                    dateMenu3: false,
                    dateMenu1: [],
                    dateMenu2: false,
                    save_button: false,
                    next_button: true,
                    success: false,
                    error: false,
                    number_of_second_form: 1,
                    HotelCustomerID: 1,
                    price: 0,
                    ServicePrice: 0,
                },
                paymentType: ["CASH", "CHEQUE", "CARD"],
                formData_1: {
                    CheckInDate: "",
                    CheckOutDate: "",
                    customerType: [],
                    roomCategoryID: [],
                    houseTypeCount: [],
                    totalFemale: 0,
                    totalMale: 0,
                    totalChildren: 0,
                    contactPerson: [
                        {
                            name: "",
                            phoneNumber: "",
                            email: "",
                            address: "",
                        },
                    ],
                },
                formData_2: [],
                formData_2_Object: {},
                formDatas: {
                    CheckInDate: "",
                    CheckOutDate: "",
                },
                BillForm: {},
                paxList: [],
                rooms: [],
                services: [],
                visit: [],
                house: [],
                country: [],
                reportIsMonthWise: false,
                females: [],
                males: [],
                houseType: [],
                ageGroup: [],
                children: [],
                packages: [],
                RoomObject: {},
                countries: [],
                state: [],
                genderList: [],
                selectedRoomData: [],
                validaty: [],
                picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
            };
        },

        computed: {
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
            paxDropDownList() {
                return this.paxList.map(item => {
                    return {
                        ...item,
                        value: item.value + `( ${item.availableCount} )`
                    }
                })
            },
            selectedHouse() {
                return this.paxDropDownList.filter(item => {
                    return this.formData_1.roomCategoryID.some(it => it === item.id)
                })
            },
            selectedHouseCountSum() {
                return this.formData_1.houseTypeCount.reduce((prev, cur) => prev + (+cur), 0)
            },
            selectedHouseErrors() {
                let errorMessage = []
                return this.selectedHouse.map((item, index) => {
                    errorMessage = []
                    if (this.formData_1.houseTypeCount[index] > item.availableCount) {
                        errorMessage.push(
                            `${this.$t("only")} ${item.availableCount} ${this.$t("pax_is_available_in_this_house")}`
                        )
                    }
                    if (this.selectedHouseCountSum > this.formData_1.NumberOfPerson) {
                        errorMessage.push(
                            `${this.$t("only")} ${this.formData_1.NumberOfPerson} ${this.$t("total_customer_expected")}`
                        )
                    }
                    return errorMessage
                })
            },
            emailErrors() {
                const errors = [];
                if (this.error) {
                    if (!this.$v.formData_1.contact.email.$dirty) return errors;
                    !this.$v.formData_1.contact.email.email &&
                        errors.push(`${this.$t("must_be_valid_email")}`);
                    !this.$v.formData_1.contact.email.required &&
                        errors.push(`${this.$t("email_is_required")}`);
                }
                return errors;
            },
            selectHouseErrors(){
                    const errors = [];
                if (!this.$v.formData_1.roomCategoryID.$dirty) return errors;
                !this.$v.formData_1.roomCategoryID.required &&
                        errors.push(`${this.$t("house")} ${this.$t("is_required")}`);
                    return errors;
            
            },

            NumberOfPersonErrors() {
                const errors = [];
                if (!this.$v.formData_1.NumberOfPerson.$dirty) return errors;
                !this.$v.formData_1.NumberOfPerson.required &&
                    errors.push(`${this.$t("no_of_person")} ${this.$t("is_required")}`);
                return errors;
            },
            NumberOfMaleErrors() {
                const errors = [];
                if (!this.$v.formData_1.totalMale.$dirty) return errors;
                !this.$v.formData_1.totalMale.maxValueValid &&
                    errors.push(`${this.$t("total_number_of_people_exceeded")}`);
                return errors;
            },
            NumberOfFemaleErrors() {
                const errors = [];
                if (!this.$v.formData_1.totalFemale.$dirty) return errors;
                !this.$v.formData_1.totalFemale.maxValueValid &&
                    errors.push(`${this.$t("total_number_of_people_exceeded")}`);
                return errors;
            },
            NumberOfChildrenErrors() {
                const errors = [];
                if (!this.$v.formData_1.totalChildren.$dirty) return errors;
                !this.$v.formData_1.totalChildren.maxValueValid &&
                    errors.push(`${this.$t("total_number_of_people_exceeded")}`);
                return errors;
            },
            CheckInDateErrors() {
                const errors = [];
                if (!this.$v.formData_1.CheckInDate.$dirty) return errors;
                !this.$v.formData_1.CheckInDate.required &&
                    errors.push(`${this.$t("check_in_date")} ${this.$t("is_required")}`);
                return errors;
            },
            CheckOutDateErrors() {
                const errors = [];
                if (this.formData_1.CheckInDate >= this.formData_1.CheckOutDate) return errors;
                if (!this.$v.formData_1.CheckOutDate.$dirty) return errors;
                !this.$v.formData_1.CheckOutDate.required &&
                    errors.push(`${this.$t("check_out_date")} ${this.$t("is_required")}`);
                return errors;
            },
            RoomErrors() {
                const errors = [];
                if (!this.$v.formData_1.Room.$dirty) return errors;
                !this.$v.formData_1.Room.required &&
                    errors.push(`${this.$t("room")} ${this.$t("is_required")}`);
                return errors;
            },
            sumOfMaleFemaleChildren() {
                return this.formData_1.totalMale + this.formData_1.totalFemale + this.formData_1.totalChildren
            }
        },
        validations() {
            return {
                formData_1: {
                    roomCategoryID: { required },
                    NumberOfPerson: { required },
                    CheckInDate: { required },
                    CheckOutDate: { required },
                    totalMale: {
                        maxValueValid: () => maxValueValids(this)
                    },
                    totalFemale: {
                        maxValueValid: () => maxValueValids(this)
                    },
                    totalChildren: {
                        maxValueValid: () => maxValueValids(this)
                    },
                },
                contact: {
                    email: {required}
                }
            }
        },
        async mounted() {
           await this.getCustomerType();
        
            //let provNepali = localStorage.getItem('lang');
            //this.currentCustomerTypes = [];
            //if (provNepali == 'ne') {

            //    this.currentCustomerTypes = this.customerTypes.filter(function (data) {
            //        return data.IsNp===true;
            //    });
            //}
            //else {
            //    this.currentCustomerTypes = this.customerTypes.filter(function (data) {
            //        return data.IsNp === false;
            //    });
            //}
            //console.log(this.currentCustomerTypes)
          
    this.formData();
    this.addForm_2_Parameter();
    },

        methods: {
            roomCategoryChanged() {
                console.log('this.formData_1.roomCategoryID', this.formData_1.roomCategoryID)
            },
            async getPAX() {
                const paxCount = await axios.get(
                    "Hotel/GetAvailablePax/" + this.formData_1.CheckInDate + "/" + this.formData_1.CheckOutDate
                );
                this.paxList = paxCount.data;
                console.log(this.paxList)
            },
           
    addContacts() {
      this.formData_1.contactPerson.push({
        name: "",
        phoneNumber: "",
        email: "",
        address: "",
      });
    },
    changeCustomerFormCheckoutDate(index) {
      let arr = [];
      this.selectedRoomData[index].selectedData.filter((response) => {
        arr.push(response.roomID);
      });
      let roomID = arr.join(",");
      let data = {
        bookedFromDate: this.formData_2[index].HotelInformation.CheckInDate,
        bookedToDate: this.formData_2[index].HotelInformation.CheckOutDate,
        isDropDown: true,
        RoomIDList: roomID,
      };
      axios
        .post("Hotel/RoomStatusListAsync", data)
        .then((response) => {
          this.selectedRoomData[index].selectedData = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUserData(index) {
      if (this.trackID) {
        this.userInfo = [];
        axios
          .get("Track/GetPersonInformationByID", {
            params: {
              ID: this.trackID,
              Type: this.trackType,
            },
          })
          .then((response) => {
            console.log(this.formData_2[index], response.data);
            this.formData_2[index].fullName = response.data.fullName;
            this.formData_2[index].country = response.data.country;
            this.formData_2[index].state = response.data.state;
            this.formData_2[index].gender = response.data.gender;
            this.formData_2[index].age = response.data.age;
            this.formData_2[index].citizenShipNumber =
              response.data.citizenShipNumber;
            this.formData_2[index].passportNumber =
              response.data.passportNumber;
            this.formData_2[index].passportNumber =
              response.data.passportNumber;
            this.formData_2[index].licenseNumber = response.data.licenseNumber;
            if (response.data.country) {
              this.getState(response.data.country);
            }
            this.trackID = "";
          });
      }
    },

    getCitizenData(index, data) {
      console.log("citizen", index, data);
      axios
        .get("Shared/GetPersonInformationByCitizenshipAsync", {
          params: {
            CitizenShipNumber: data.value,
          },
        })
        .then((response) => {
          this.formData_2[index] = response.data;
          if (response.data.country) {
            this.getState(response.data.country);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPassportData(index, data) {
      console.log("passport", index, data);
      axios
        .get("Shared/GetPersonInformationByPassportAsync", {
          params: {
            PassportNumber: data.value,
          },
        })
        .then((response) => {
          this.formData_2[index] = response.data;
          if (response.data.country) {
            this.getState(response.data.country);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getState(val) {
      const stateList = await axios.get(
        "Shared/StateListByCountryCode?CountryCode=" + val
      );
      this.state = stateList.data;
    },
    formClose(val) {
      console.log(val);
      this.$emit("formResponseClose", val);
    },
    addForm_2_Parameter() {
      let allRoom = this.rooms;
      let selectedData = [];
      allRoom.filter((res) => {
        if (this.storeRoom) {
          this.storeRoom.filter((rooms) => {
            if (parseInt(rooms) == res.roomID) {
              selectedData.push(res);
            }
          });
        }
      });
      this.selectedRoomData.push({
        selectedData,
      });
      this.validaty.push({
        IDValidity: "",
        VisaValidity: "",
      });
      this.formData_2.push({
        firstName: "",
        lastName: "",
        country: this.countries,
        state: "",
        gender: "",
        age: "",
        citizenShipNumber: "",
        passportNumber: "",
        licenseNumber: "",
        otherID: "",
        Document: "",
        IsVerifiedDocument: false,
        HotelInformation: {
          RoomID: "",
          CheckInDate: this.formDatas.CheckInDate,
          CheckOutDate: this.formDatas.CheckOutDate,
        },
      });
      this.dropdownData.push({
        citizenShipNumber: null,
        passportNumber: null,
      });
    },
    async formData() {
      // const packagelist = await axios.get("Hotel/DDLPackageListAsync");
      // const servicelist = await axios.get("Hotel/DDLHotelServiceListAsync");
      axios
        .get("Shared/CountryList")
        .then((response) => {
          this.listCountries = response.data;
        })
        .catch((err) => {
          throw err;
        });

      axios
        .get("Shared/GenderList")
        .then((res) => {
          this.genderList = res.data;
        })
        .catch((err) => {
          throw err;
        });

      axios
        .get("Shared/TouristTypeList")
        .then((res) => {
          this.touristTypeList = res.data;
        })
        .catch((err) => {
          throw err;
        });

      axios
        .get("Shared/PurposeOfVisit")
        .then((res) => {
          this.visit = res.data;
        })
        .catch((err) => {
          throw err;
        });

      axios
        .get("Hotel/HouseList")
        .then((res) => {
          this.houseType = res.data;
        })
        .catch((err) => {
          throw err;
        });

      axios
        .get("Shared/GetAgeGroupList")
        .then((res) => {
          this.ageGroup = res.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    getServicePrice() {
      let param = { HotelCustomerServiceID: this.formData_1.Service };
      axios
        .post("Hotel/GetHotelServiceAmount", param)
        .then((response) => {
          this.snackbar.ServicePrice = response.data;
          console.log(this.snackbar.price);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Could not Fetch the price";
        });
    },
    getPackagePrice(val) {
      axios
        .post("Hotel/GetHotelPakageAmount", val)
        .then((response) => {
          this.snackbar.price = response.data;
          console.log(this.snackbar.price);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Could not Fetch the price";
        });
    },
    getPrice() {
      let fareAmountParam = {
        roomIDs: this.formData_1.Room,
        packageID: parseInt(this.formData_1.Package),
        isCustomerOnPackage: this.formData_1.isCustomerOnPackage,
        NumberOfPerson: parseInt(this.formData_1.NumberOfPerson),
      };
      axios
        .post("Hotel/GetPackageOrRoomFareAmountAsync", fareAmountParam)
        .then((response) => {
          this.snackbar.price = response.data;
          console.log(this.snackbar.price);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Could not Fetch the price";
        });
    },
    form_1Post() {
      //debugger
      //this.$v.formData_1.$touch();
      //if (this.$v.formData_1.$invalid) {
      //    this.submitStatus = "ERROR";
      //} else {
      this.formDatas = {
        //NumberOfPerson: parseInt(this.formData_1.NumberOfPerson),
        // Country: this.formData_1.Country,
        // State: this.formData_1.State,
        // Address: this.formData_1.Address,
        // ContactNumber: this.formData_1.ContactNumber,
        //CheckInDate: this.formData_1.CheckInDate,
        //CheckOutDate: this.formData_1.CheckOutDate,
        //Room: this.formData_1.Room,
        // HotelCustomerServiceID: this.formData_1.Service,
        // HotelPackageID: this.formData_1.Package,
        // IsCustomerOnPackage: this.formData_1.IsCustomerOnPackage

        NumberOfPerson:
          this.formData_1.NumberOfPerson != null &&
          this.formData_1.NumberOfPerson != undefined
            ? parseInt(this.formData_1.NumberOfPerson)
            : 0,

        AgeGroup:
          this.formData_1.ageGroup != null &&
          this.formData_1.ageGroup != undefined
            ? this.formData_1.ageGroup.toString()
            : "",
        Country:
          this.formData_1.countries != null &&
          this.formData_1.countries != undefined
            ? this.formData_1.countries.toString()
            : "NPL",
        // State: this.formData_1.State,
        // Address: this.formData_1.Address,
        // ContactNumber: this.formData_1.ContactNumber,
        CheckInDate: this.formData_1.CheckInDate,
        CheckOutDate: this.formData_1.CheckOutDate,
        TouristType:
          this.formData_1.customerType != null &&
          this.formData_1.customerType != undefined
            ? this.formData_1.customerType.toString()
            : "",
        //Name: this.formData_1.name.toString(),
        PersonList: this.formData_1.contactPerson,
        HouseTypeCount: this.formData_1.houseTypeCount,
        RoomCategoryID: this.formData_1.roomCategoryID,
        //Email: this.formData_1.email.toString(),
        //Address: this.formData_1.address.toString(),
        TotalMale:
          this.formData_1.totalMale != null &&
          this.formData_1.totalMale != undefined
            ? parseInt(this.formData_1.totalMale)
            : 0,
        TotalFemale:
          this.formData_1.totalFemale != null &&
          this.formData_1.totalFemale != undefined
            ? parseInt(this.formData_1.totalFemale)
            : 0,
        totalChildren:
          this.formData_1.totalChildren != null &&
          this.formData_1.totalChildren != undefined
            ? parseInt(this.formData_1.totalChildren)
            : 0,
        //Room: JSON.stringify(this.formData_1.Room),
        HouseName:
          this.formData_1.roomCategoryID != null &&
          this.formData_1.roomCategoryID != undefined
            ? this.formData_1.roomCategoryID.toString()
            : "",
        purposeOfVisit:
          this.formData_1.purposeOfVisit != null &&
          this.formData_1.purposeOfVisit != undefined
            ? this.formData_1.purposeOfVisit.toString()
            : "",
        InternationalCustomer:
          this.formData_1.internationalCustomer != null &&
          this.formData_1.internationalCustomer != undefined
            ? parseInt(this.formData_1.internationalCustomer)
            : 0,
        DomesticCustomer:
          this.formData_1.domesticCustomer != null &&
          this.formData_1.domesticCustomer != undefined
            ? parseInt(this.formData_1.domesticCustomer)
            : 0,
        //roomTypeID: this.formData_1.roomTypeID.toString(),
        // HotelCustomerServiceID: this.formData_1.Service,
        // HotelPackageID: this.formData_1.Package,
        // IsCustomerOnPackage: this.formData_1.IsCustomerOnPackage
      };
      console.log(this.formDatas);
      //this.storeRoom = this.formData_1.Room
      axios
        .post("Hotel/InsertIntoHomestayCustomerAsync", this.formDatas)
        .then((response) => {
          console.log(response.data);
          this.$v.formData_1.$reset();
          this.snackbar.success = response.data.success;
          //this.snackbar.number_of_second_form = this.formDatas.NumberOfPerson;
          this.snackbar.next_button = !this.snackbar.next_button;

          this.snackbar.HotelCustomerID = response.data.id;
          this.snackbar.error = false;
          this.snackbar.snackbar = false;
          //for (
          //    let step = 1;
          //    step < this.snackbar.number_of_second_form;
          //    step++
          //) {
          //    console.log("inside loop", step);
          //    this.addForm_2_Parameter();
          //}
          //this.formData_2[0].HotelInformation.CheckInDate = this.formDatas.CheckInDate
          //this.formData_2[0].HotelInformation.CheckOutDate = this.formDatas.CheckOutDate

          //let allRoom = this.rooms
          //let selectedData = []
          //allRoom.filter(res => {
          //    if (this.storeRoom) {
          //        this.storeRoom.filter(rooms => {
          //            if (parseInt(rooms) == res.roomID) {
          //                selectedData.push(res)
          //            }
          //        })
          //    }
          //})

          //this.selectedRoomData[0].selectedData = selectedData
          //this.e6++;
          this.snackbar.next_button = false;
          this.snackbar.save_button = true;
          this.snackbar.back_button = true;
          this.snackbar.text = "Customer Added Successfully !";
          this.formData_1 = [];
          this.$emit("formResponse", this.snackbar);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Internal Server Error, Please Contact Admin";
        });
      //}
    },
    form_2Post() {
      /*this.e6 = 4;*/
      this.formData_2_Object.List = this.formData_2;
      this.formData_2_Object.HotelCustomerID = this.snackbar.HotelCustomerID;

      console.log("Data:", this.formData_2_Object);
      axios
        .post(
          "Hotel/InsertIntoHotelCustomerInformationAsync",
          this.formData_2_Object
        )
        .then((response) => {
          console.log(response.data);
          this.snackbar.success = response.data.success;
          this.snackbar.number_of_second_form = 1;
          this.snackbar.next_button = !this.snackbar.next_button;
          this.formData_2_Object = {};
          // this.e6++;
          this.snackbar.next_button = false;
          this.snackbar.save_button = true;
          this.snackbar.text = "Customer Added Successfully !";
          this.$emit("formResponse", this.snackbar);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Internal Server Error, Please Contact Admin";
        });
    },
    form_3Post() {
      // this.BillForm.HotelCustomerID = this.snackbar.HotelCustomerID
      let form = {
        HotelCustomerID: this.snackbar.HotelCustomerID,
        PaidAmount: parseFloat(this.BillForm.PaidAmount),
        PaymentType: this.BillForm.PaymentType,
        DueAmount: parseFloat(this.BillForm.DueAmount),
        AmountToPay: parseFloat(
          this.snackbar.price + this.snackbar.ServicePrice
        ),
      };

      axios
        .post("Hotel/InsertIntoHotelCustomerBillAsync", form)
        .then((response) => {
          console.log(response.data);
          this.snackbar.success = response.data.success;
          this.snackbar.next_button = false;
          this.snackbar.save_button = true;
          this.snackbar.text = "Customer Added Successfully !";
          this.$emit("formResponse", this.snackbar);
        })
        .catch(() => {
          this.snackbar.error = true;
          this.snackbar.snackbar = true;
          this.snackbar.success = false;
          this.snackbar.text = "Internal Server Error, Please Contact Admin";
        });
    },
    reset() {
      this.save_button = false;
      this.e6 = 1;
      this.$emit("FormClose", this.dialogue);
      this.snackbar.next_button = true;
    },
    checkRate(ActualRate, PaidPrice) {
      this.BillForm.DueAmount =
        ActualRate.price + ActualRate.ServicePrice - PaidPrice;
    },
    async GetRoomStatus() {
      let data = {
        roomIDs: "",
        bookedFromDate: this.formData_1.CheckInDate,
        bookedToDate: this.formData_1.CheckOutDate,
        isDropDown: true,
      };
      axios
        .post("Hotel/RoomStatusListAsync", data)
        .then((response) => {
          this.rooms = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
            },
    async getCustomerType() {
      let type = localStorage.getItem('lang');
         axios
         .get("Hotel/GetCustomerTypes/" + type)
         .then((response) => {
             this.customerTypes = response.data;
             console.log(this.customerTypes)
               })
                .catch((err) => {
                 console.log(err);
         });
        },
    },
};
</script>

<style scoped>
.v-expansion-panel-header {
  background-color: #6061862b;
}
</style>
